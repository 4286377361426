<template>
  <v-card max-width="800" class="mx-auto" elevation="0">
    <v-card-title class="justify-center display-1"> {{ name }}</v-card-title>
    <v-card-subtitle class="justify-center text--primary">
      {{ description }}
    </v-card-subtitle>
    <v-card-actions class="justify-center">
      <v-btn :href="url" target="blank" color="blue" text> Visit site </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Site",
  props: {
    url: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>
