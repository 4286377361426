<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/diu-logo-big.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-5" cols="12">
        <v-col class="pb-10">
          <!-- <h1 class="display-2 font-weight-bold mb-3">Challenges</h1> -->

          <h2 class="subheading font-weight-regular">
            Together with other Humanitarian Assistance and Disaster Response (HADR) organizations,
            the <a href="https://www.diu.mil/"> Defense Innovation Unit</a> runs a series of 
            computer vision challenges called xView to stimulate innovation and solve key analytical
            bottlenecks. 
          </h2>
        </v-col>

        <v-col v-for="(site, i) in sites" :key="i">
          <Site
            :url="site.url"
            :name="site.name"
            :description="site.description"
          />
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Site from "./Site.vue";

export default {
  name: "Landing",

  components: {
    Site,
  },
  data: () => ({
    sites: [
      {
        name: "Object Detection",
        url: "http://xviewdataset.org/",
        description:
          "The first xView challenge focused on using high-resolution commercial satellite imagery to detect a wide variety of objects as they actually appear in the world, with realistic occurence frequency (common vs. rare), size ranges (cars vs. airports), and fields of view. The challenge has finished but data are still available for download.",
      },
      {
        name: "Building Damage Assessment",
        url: "https://xview2.org",
        description:
          "xView2 used high-resolution RGB satellite imagery to identify buildings and score damage level before/after a variety of natural disasters around the world. The winning models were deployed in various disaster relief efforts around the world and data remain available for download.",
      },
      {
        name: "Coming Soon",
        url: "",
        description: "Keep an eye out, more information coming soon!",
      },
    ],
  }),
};
</script>
